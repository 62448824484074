
import { defineComponent, ref, unref, toRefs, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import { useCreateWithdrawal } from '@/composables/api';
import { PartialWithdrawal } from '@/interfaces/Withdrawal';
import { normalizeRequestData } from '@/utils/normalization';
import { CREATED_SUCCESSFULLY_TEXT, ResponseError } from '@/apis';
import { RuleItem } from 'async-validator';
import { getWithdrawalOverview } from '@/apis/withdrawal';

const DEFAULT_FORM_VALUES: PartialWithdrawal & {
  } = {
    appliedAmount: null
  };

const isValidAmount = (value: number) => {
  if (value > 6000 || value < 50000) {
    return new Error('提領金額最低 6000，最高 50000');
  } else {
    return true;
  }
};

const rules = {
  appliedAmount: [
    {
      required: true,
      trigger: 'blur',
      validator: (rule: RuleItem, value: number) => {
        if (!rule.required && !value) {
          return true;
        }

        return isValidAmount(value);
      }
    }
  ]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const isFormLoading = ref(true);
    const router = useRouter();
    const formRef = ref<typeof ElForm>();
    const data = ref();

    const { isLoading: isCreating, mutate: create } = useCreateWithdrawal();

    const fetchWithdrawalOverview = async() => {
      data.value = (await getWithdrawalOverview()).data;
    };

    const submitForm = () => {
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          create(
            { data: normalizeRequestData(formValues.value) },
            {
              onSuccess() {
                ElMessage.success(CREATED_SUCCESSFULLY_TEXT);

                formValues.value = DEFAULT_FORM_VALUES;

                router.go(-1);
              },
              onError(error: ResponseError) {
                ElMessage.error(error.response?.data.error.message);
              }
            }
          );
        }
      });
    };

    onMounted(() => {
      if (!isEdit.value) {
        isFormLoading.value = false;
      }

      fetchWithdrawalOverview();
    });

    return {
      data,
      rules,
      formRef,
      formValues,
      isFormLoading,
      isCreating,
      submitForm
    };
  }
});
